import React from "react"
import "../scss/index.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import Hero from "../components/hero/hero"
import Double from "../components/layout/double"
// import Quad from "../components/layout/quad"
import Footer from "../components/layout/footer"
import Icon1 from "../../static/icons/icon-1.svg"
import Icon2 from "../../static/icons/icon-3.svg"
import Icon3 from "../../static/icons/icon-2.svg"
import Icon4 from "../../static/icons/icon-4.svg"
import Image1 from "../../static/img/eco-banner-new-2.jpg"
import LogoSteam from "../../static/icons/icon-steam-logo.svg"
import { Helmet } from "react-helmet"


export default function Home() {
  return <>
  <Helmet>
          <meta charSet="utf-8" />
          <title>Eco Wash Solutions - Truck Wash | Anywhere Anytime We Come To You</title>
          <link rel="canonical" href="https://ecocarwash.co.nz" />
        </Helmet>
  <Header />
  <Layout>
  {/* Hero Block */}
  <Hero pretitle="Modern Edquipment" title="Truck Wash Solutions" description="Anywhere Anytime We Come to You" />
   {/* Intro Block */}
   <Double align="left" type="light">
   <div className="double-block" id="about">
      {/* <h6 className="sub-heading">Modern Equipment</h6> */}
      <h2 className="heading">Professional washing and cleaning of your truck</h2>
      <p>Eco Car Wash has been delivering result since 2013. Our goal is to provide both superior customer experience and the tremendous value of our customers.
      Eco car wash has good experience in mobile car wash service and we are the pre-eminent mobile car groomers in Auckland. Our loyal customers provide a 5star review on our Facebook Page. 
      We are also very passionate about exceeding customers expectations. <br />
      We have already been develivering a result in the mobile car wash and now we have planned to expand our business by going into the mobile truck wash industry.
      <br /></p>
      <h6 className="sub-heading sub-heading--basic">Eco Car Wash proudly introduces</h6>
      <p>We come at your work, yard anywhere with water power.<br />
      Our two self contained vans has latest equipment to do proper job. <br />
      Our vans holds 4000 Lts of water so we can wash 15 - 20 trucks at one place. <br />      <br /></p>

      <h6 className="sub-heading sub-heading--basic">Why choose Mobile tuck wash</h6>
      <p>Normally owner operator or driver need to take their truck at truck wash . It requires time money/ wages and RUC.
      Eco Wash Solutions - Truck Wash offer you full mobile service at your place without any cost . We cover the whole of Auckland without any hassle.<br /><br /></p>

      <img src={LogoSteam} width="400px"/>
      <p>We also offer hot/steam clean for your fleet. Hot water/steam can easily get rid off mud, grease, oil and road tar from your vehicle. Whatever cold water cleans, hot water/steam does it better &amp; faster.</p>
      
      <h6 className="sub-heading sub-heading--basic">Environmentally - Friendly</h6>

      <p>Eco Car Wash uses all products that are water-based and they are all made in New Zealand. </p>
      <AnchorLink to="/#contact" title="Contact Us" className="button button--large button--primary">
      Find out more..
      </AnchorLink>
      </div>
      <div className="double-block">
        <img src={Image1} alt="Eco Wash Solutions - Truck Wash" title="Eco Wash Solutions - Truck Wash" lazyload="true" width="100%" />
      </div>
   </Double>   
   {/* Icons / Services */}
   {/* <Quad id="services">
    <div className="block-item">
      1. SVG
    </div>
    <div className="block-item">
      2. SVG
  </div>
  <div className="block-item">
      3. SVG
  </div>
  <div className="block-item">
      4. SVG
  </div>
   </Quad> */}

   {/* Pricing Block */} 
   <div className="pricing-block container--dark container--centered" id="prices">
     <div className="container">
     {/* <h6 className="sub-heading sub-heading--basic align--center">Negotiable pricing</h6> */}
     <h2 className="heading align--center">Our Prices</h2>
      <p className="align--center">All pricing are negotiable based on the frequency of washes and number of fleet provided at a time.</p>
      <div className="pricing-block-cards">
        <div className="pricing-block--card">
          <img src={Icon2} alt="" loading="lazy" />
          <h5 className="align--center">Curtain &amp; Box Body</h5>
            <h4 className="pricing-block-price"><span className="pre">from</span><span className="currency">$</span>80<span className="decimal">.00</span><span className="gst"> plus GST</span></h4>
        </div>
        <div className="pricing-block--card">
        <img src={Icon1} alt="" loading="lazy" />
          <h5 className="align--center">Flat Deck</h5>
          <h4 className="pricing-block-price"><span className="pre">from</span><span className="currency">$</span>90<span className="decimal">.00</span><span className="gst"> plus GST</span></h4>
        </div>
        <div className="pricing-block--card">
          <img src={Icon3} alt="" loading="lazy" />
          <h5 className="align--center">Truck or Tractor with Flat Deck Trailer</h5>
          <h4 className="pricing-block-price"><span className="pre">from</span><span className="currency">$</span>175<span className="decimal">.00</span><span className="gst"> plus GST</span></h4>
        </div>
        <div className="pricing-block--card">
        <img src={Icon4} alt="" loading="lazy" />
          <h5 className="align--center">Truck or Tractor with CS Trailer</h5>
          <h4 className="pricing-block-price"><span className="pre">from</span><span className="currency">$</span>200<span className="decimal">.00</span><span className="gst"> plus GST</span></h4>
        </div>
      </div>
      <p className="align--center">*Prices may vary according to the number of axles of your truck.</p>
     </div>
   </div>

   {/* Contact Block */}
   <Double align="right" type="light" id="contact">
     <div className="double-block">
       <h6  className="sub-heading">Get a Quote</h6>
       <h2 className="heading">Contact Us</h2>
      <p>For any enquiries regarding our services, please contact at details below or get in touch via our contact form.</p>
      {/* <Button title="Find out More" size="large" type="primary" link="#" /> */}
      <p>M: <a className="styled-link" href="tel:021 724 555">021 724 555</a><br />
         P: <a className="styled-link" href="tel:09 215 4117">09 215 4117</a><br />
        E: <a className="styled-link" href="mailto:info@ecocarwash.co.nz">info@ecocarwash.co.nz</a></p>
        <p>We also provide car &amp; small vehicles wash service. Check out our website <a href="https://ecocarwash.co.nz" target="_blank" rel="noreferrer">www.ecocarwash.co.nz</a></p>

     </div>
      <div className="double-block">
      <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" className="contact-form">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
          <label>
            <input type="text" name="name" id="name" placeholder="Name" />
          </label>
          <label>
            <input type="email" name="email" id="email" placeholder="Email" />
          </label>
          <label>
            <input type="text" name="subject" id="subject" placeholder="Subject" />
          </label>
          <label>
            <textarea name="message" id="message" rows="5" placeholder="Message" />
          </label>
          <button type="submit" className="button button--large button--primary">Send</button>
          <input type="reset" className="button button--large button--secondary" value="Clear" />
          </form>
      </div>
   </Double>   

    {/* Testimonials - To be added in future */}

  </Layout>
  <Footer copyright="Copyright &copy; 2020 - 2022 Eco Wash Solutions. All Rights Reserved" />
  </>
}

import React, { Component } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

 class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }
  handleClick = () => {
    if (window.innerWidth > 800) {
      return;
    }
    this.setState({ active: !this.state.active });
   };

render() {

    const { isActive } = this.state

    return (
  <div className="nav-container">
   <a href="#"  role="button"
                            className={
                              "navbar-burger burger " + (this.state.active ? "is-active" : "")
                            }
                            aria-label="menu"
                            data-target="nav-list"
                            aria-expanded="false"
                            onClick={this.handleClick}
                        >
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>    
      <nav className={"nav-nav " + (this.state.active ? "is-active" : "")}>
      <ul className="nav-list" id="nav-list">
        <li onClick={this.handleClick}><a href="#">Home</a></li>
        <li onClick={this.handleClick}>
          <AnchorLink to="/#about" title="About Us" > 
            <span>About Us</span>
          </AnchorLink>
          </li>
        <li onClick={this.handleClick}><AnchorLink to="/#prices" title="Out Prices">
            <span>Our Prices</span>
          </AnchorLink></li>
        {/* <li><AnchorLink to="/#testimonials" title="Testiominials">
            <span>Testimonials</span>
          </AnchorLink></li> */}
        <li onClick={this.handleClick}><AnchorLink to="/#contact" title="Contact Us">
            <span>Contact Us</span>
          </AnchorLink></li>
      </ul>
    </nav>
  </div>
    )
  }
 }

export default Navigation;
import React from "react"
import Navigation from "../navigation/navigation"
import logoMain from "../../../static/icons/eco-wash-solutions-logo-light.svg"

export default function Header({ children }) {
  return <>
  <header className="header-container">
    <div className="container">
      <div className="header-innerContainer">
      <div className="header-logoContainer">
        <a href="/"><img className="header-logoMain" src={logoMain} alt="Eco Wash Solutions - Truck Wash" /></a>
      </div>
      <Navigation />
      </div>
    </div>
  </header>
  </>
}